// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-sales-js": () => import("./../../src/pages/contact-sales.js" /* webpackChunkName: "component---src-pages-contact-sales-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-technical-support-js": () => import("./../../src/pages/technical-support.js" /* webpackChunkName: "component---src-pages-technical-support-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-feature-post-js": () => import("./../../src/templates/feature-post.js" /* webpackChunkName: "component---src-templates-feature-post-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-product-post-js": () => import("./../../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-tags-page-js": () => import("./../../src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */)
}

